var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "remote-inspection",
      style: { width: _vm.imgStyle.width, height: _vm.imgStyle.height },
    },
    [
      _c(
        "div",
        { staticClass: "picWrapper", style: _vm.styleWrapper },
        _vm._l(_vm.curPics, function (pic, ind) {
          return _c(
            "div",
            {
              key: ind,
              staticClass: "picItem",
              style: {
                "background-image": `url(${pic.picUrl})`,
                ..._vm.imgStyle,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _vm.showDesc
                ? _c("div", { staticClass: "desc" }, [
                    _vm._v(_vm._s(pic[_vm.showDesc])),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.curPics.length != 0,
              expression: "curPics.length != 0",
            },
          ],
          staticClass: "buttonWrapper",
        },
        [
          _c(
            "div",
            {
              staticClass: "left-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.leftClick.apply(null, arguments)
                },
              },
            },
            [_vm._v("<")]
          ),
          _c(
            "div",
            {
              staticClass: "right-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.rightClick.apply(null, arguments)
                },
              },
            },
            [_vm._v(">")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }