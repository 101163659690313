<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <div class="searchWrapper">
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Road_name')">
                <el-autocomplete
                  v-model="formInline.parkName"
                  class="inline-input"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                @click="
                  page = 1;
                  getBerth();
                "
                type="primary"
                icon="el-icon-search"
                >查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button @click="changePole('prev')" type="primary">上一个车场 </el-button>
              <el-button @click="changePole('next')" type="primary">下一个车场 </el-button>
              <el-button plain @click="top()" type="success" :disabled="!formInline.parkId"
                >上报故障
              </el-button>
            </div>
            <div class="col_right mbd4"></div>
          </div>
          <el-row>
            <el-col :span="12">
              <!-- <el-form-item label="数据来源" prop="dataResource">
              <el-select
                @change="equipChange"
                v-model.trim="formInline.equId"
                size="12"
              >
                <el-option
                  :label="val.equipmentName"
                  :value="val.equipmentId"
                  v-for="(val, ind) in equipList"
                  :key="ind"
                ></el-option>
              </el-select>
            </el-form-item> -->
            </el-col>
            <el-col :span="12" style="text-align: right">
              <!-- :disabled="formInline.equId && parkId && !ifReport" -->
              <el-button @click="changePole('prev')" type="primary"
              >上一条道路
              </el-button
              >
              <el-button @click="changePole('next')" type="primary"
              >下一条道路
              </el-button
              >
              <el-button
                  plain
                  @click="top()"
                  type="success"
                  :disabled="!formInline.parkId"
              >上报故障
              </el-button
              >
              <!-- <el-button @click="switchEquip(-1)" type="success"
              >上一设备</el-button
            >
            <el-button @click="switchEquip(1)" type="success"
              >下一设备</el-button
            > -->
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-row class="bgFFF">
        <el-col :span="6" v-for="(item, key) in inspectionData" :key="key">
          <div class="imageClass" @click="picClick(item)">
            <img
              :class="item.featPictureURL ? '' : 'noPic'"
              :src="item.featPictureURL ? item.featPictureURL : imgSrc"
              alt
            />
            <a class="berth">{{ item.berthCode }}</a>
          </div>
        </el-col>
      </el-row>
      <!--分页器-->
      <div class="pagerWrapper bgFFF paddingB10" style="padding-right: 20px">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog title="上报故障" v-if="dialogVisible" :visible.sync="dialogVisible" width="30%">
        <el-form ref="form" label-width="80px">
          <el-form-item label="车位编号">
            <el-select v-model="region" placeholder="请选择车位编号">
              <el-option
                v-for="item in berthList"
                :label="item.berthCode"
                :value="item.berthId"
                :key="item.berthId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="故障名称">
            <el-row>
              <el-col :span="8" v-for="(info, index) in topState" :key="info.type">
                <div
                  :class="info.checkedState == true ? 'faultHover' : 'faultClass'"
                  @click="faultState(info, index)"
                >
                  {{ info.text }}
                </div>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-row style="margin-top: 50px">
              <el-col :span="12" style="text-align: center">
                <el-button type="primary" plain @click="close" style="width: 100px"
                  >取消
                </el-button>
              </el-col>
              <el-col :span="12" style="text-align: center">
                <el-button @click="sureFault" type="primary" style="width: 100px"
                  >上报故障
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="回溯图片" class="prePic" :visible.sync="prePicVisible" width="1040px">
        <div class="float_left">
          <el-date-picker
            v-model="initTime"
            type="datetime"
            @change="timeChange"
            :clearable="false"
            placeholder="选择日期时间"
            default-time="12:00:00"
            :picker-options="pickerOptions0"
          >
          </el-date-picker>
        </div>
        <pic-wrapper
          :imgStyle="{ width: '1000px', height: '600px' }"
          :prePicVisible.sync="prePicVisible"
          :data="remoteobj"
          :initTime.sync="initTime"
        ></pic-wrapper>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import picWrapper from "@/components/remoteInspection/picWrapper";
import { dateFormat } from "@/common/js/public";

export default {
  components: {
    picWrapper,
  },
  data() {
    return {
      ifReport: false,
      selectedType: [],
      imgSrc: require("@/assets/img/car-pic.png"),
      remoteobj: {
        berthId: "1629327380716372039",
        startTime: dateFormat(new Date()),
        // url: '/acmp/2.0/picture/featPicList'
        url: "/acb/2.0/picture/featPicList",
      },
      parkId: "",
      prePicVisible: false,
      dialogVisible: false,
      equipList: [],
      page: 1,
      pageSize: 12,
      total: 0,
      equipInd: "",
      formInline: {
        // equId: "",
        parkName: "",
        parkId: "",
      },
      topState: [
        {
          text: "遮挡",
          type: "4",
          checkedState: false,
        },
        {
          text: "泊位重叠",
          type: "5",
          checkedState: false,
        },
        {
          text: "相机颜色",
          type: "2",
          checkedState: false,
        },
        {
          type: "1",
          text: "镜头角度",
          checkedState: false,
        },
        {
          text: "泊位线",
          checkedState: false,
          type: "6",
        },
        {
          text: "镜头模糊",
          checkedState: false,
          type: "3",
        },
        {
          text: "设备离线",
          type: "0",
          checkedState: false,
        },
        {
          text: "其他",
          checkedState: false,
          type: "9",
        },
      ],
      inspectionData: [
        // {
        //   berth: "XBZX00064",
        //   state: "上报故障",
        //   src: "1"
        // }
      ],
      berthList: [],
      parkList: [],
      count: 0,
      region: "",
      equipId: "",
      initTime: "",
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    changePole(str) {
      let length = this.parkList.length;
      if (str == "prev") {
        this.count--;
        if (this.count < 0) {
          this.$alert("暂无更多数据", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
            type: "warning",
          });
          this.count = 0;
          return;
        }
      } else if (str == "next") {
        this.count++;
        if (this.count > length - 1) {
          this.$alert("暂无更多数据", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
            type: "warning",
          });
          this.count = length - 1;
          return;
        }
      }
      this.formInline.parkId = this.parkList[this.count].parkId;
      // this.formInline = this.parkList[this.count];
      this.formInline.parkName = this.parkList[this.count].parkName;
      this.page = 1;
      this.getBerth();
    },
    timeChange(v) {
      this.remoteobj.startTime = dateFormat(v);
    },
    handleCurrentChange(v) {
      this.page = v;
      this.getBerth();
    },
    picClick(item) {
      this.prePicVisible = true;
      this.initTime = dateFormat(new Date());
      this.remoteobj = {
        ...this.remoteobj,
        berthId: item.berthId,
        startTime: dateFormat(new Date()),
      };
    },
    equipChange(id) {
      this.equipList.forEach((data, ind) => {
        if (data.equipmentId == id) {
          this.ifReport = data.ifReport;
          this.equipInd = ind;
          this.getBerth();
        }
      });
    },
    switchEquip(diff) {
      this.equipInd += diff;
      if (this.equipInd < 0 || this.equipInd == this.equipList.length) {
        this.equipInd -= diff;
        this.$alert("没有设备了！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.formInline.equId = this.equipList[this.equipInd].equipmentId;
      this.getBerth();
    },
    handleSelect(item) {
      for (let i = 0; i < this.parkList.length; i++) {
        if (this.parkList[i].parkName == item.parkName) {
          this.count = i;
        }
      }
      this.page = 1;
      this.formInline.parkId = item.parkId;
      this.getBerth();
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.parkList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.parkName.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
      };
    },
    // let url = "/acb/2.0/systems/loginUser/getParkName";
    // {
    //     data: {
    //       page: 0, // 模糊全部道路不分页
    //       pageSize: 0, // 模糊全部道路不分页
    //       parkName: queryString,
    //       slaveRelations: "0,1",
    //       commercialStatus: "2,3",
    //     },
    //   }
    // getEquipment(parkId) {
    //   this.$axios
    //     .get(`/acb/2.0/equipment/equipmentListsByParkId/${parkId}`, {
    //       data: { page: 0, pageSize: 0 },
    //     })
    //     .then((res) => {
    //       this.equipList = res.value;
    //     });
    // },
    top(data) {
      this.dialogVisible = true;
      this.getBerthList();
    },
    faultState(data, index) {
      // this.$set(data, "checkedState", !data.checkedState);
      this.$set(
        this.topState,
        index,
        Object.assign(this.topState[index], {
          checkedState: !this.topState[index].checkedState,
        })
      );
      if (data.checkedState) {
        this.selectedType.push(data.type);
      } else {
        this.selectedType = this.selectedType.filter((v) => {
          return v != data.type;
        });
      }
      // console.log(this.selectedType);
    },
    // 上报故障
    sureFault() {
      // console.log(this.selectedType);
      if (this.selectedType.length == 0) {
        this.$alert("请选择类型！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let arr = this.berthList.filter((v) => {
        return v.berthId == this.region;
      });
      this.$axios
        .post(`/acb/2.0/areaEquFault/add`, {
          data: {
            parkId: arr[0].parkId,
            equId: arr[0].equipId,
            faultType: this.selectedType.toString(),
          },
        })
        .then((res) => {
          this.dialogVisible = false;
          // Object.assign(this.topState, this.$options.data().topState);
          Object.assign(this.$data.topState, this.$options.data().topState);
          // console.log(this.topState);
          this.selectedType = [];
          this.$alert("操作成功！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.getBerth();
        });
    },
    close() {
      this.dialogVisible = false;
      Object.assign(this.$data.topState, this.$options.data().topState);
      this.selectedType = [];
    },
    getBerth() {
      if (!this.formInline.parkId) {
        this.$alert('请选择道路', this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine')
        });
        return;
      }
      this.$axios
        .get(`/acb/2.0/patrol/getBerthStateByParentParkId`, {
          data: {
            parkId: this.formInline.parkId,
            page: this.page,
            size: this.pageSize,
          },
        })
        .then((res) => {
          // console.log(res.value);
          // this.ifReport = res.value.ifReport;
          this.total = res.value.total * 1;
          this.inspectionData = res.value.list;
        });
    },
    getBerthList() {
      // patrol/getBerthEquipByParentParkId
      this.$axios
        .get(`/acb/2.0/patrol/getBerthEquipByParentParkId`, {
          data: {
            ...this.formInline,
          },
        })
        .then((res) => {
          this.berthList = res.value;
          this.region = this.berthList[0].berthId;
        });
    },
    getParentPark() {
      let url = "/acb/2.0/patrol/getParentPark";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.parkList = res.value;
          this.formInline.parkName = this.parkList[0].parkName;
          this.formInline.parkId = this.parkList[0].parkId;
          this.getBerth();
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  mounted() {
    this.getParentPark();
  },
};
</script>

<style scoped>
.imageClass {
  width: 80%;
  height: 250px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageClass img {
  width: 100%;
  height: 100%;
}

.berth {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: rgba(204, 204, 204, 0.6);
  text-align: center;
  line-height: 50px;
  color: #fff;
}

.bottom-title {
  cursor: pointer;
  width: 50%;
  height: 30px;
  line-height: 30px;
  border: 1px solid #9999;
  text-align: center;
  margin: 10px auto;
}

.color {
  cursor: pointer;
  width: 50%;
  height: 30px;
  line-height: 30px;
  border: 1px solid #9999;
  text-align: center;
  margin: 10px auto;
  color: #fff;
  background: rgba(204, 204, 204, 1);
}

.faultClass {
  cursor: pointer;
  width: 90%;
  height: 40px;
  line-height: 40px;
  border: 1px solid #9999;
  text-align: center;
  margin: 10px 0;
}

.faultHover {
  cursor: pointer;
  width: 90%;
  height: 40px;
  line-height: 40px;
  border: 1px solid #9999;
  text-align: center;
  margin: 10px 0;
  background: red;
  color: #fff;
}

/* .prePic {
  overflow: hidden;
} */
.float_left {
  float: right;
}
.page1 .pagerWrapper {
  margin-top: 0;
}
.pagerWrapper {
  text-align: right;
  margin-top: 18px;
  font-size: 12px;
}

.noPic {
  width: 131px !important;
  height: 30px !important;
}
</style>
